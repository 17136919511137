var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-6 text-h6",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.userData.firstName + ' ' + _vm.userData.lastName)+" ")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"align":"right"}},[(!_vm.isEditingDisabled)?_c('v-btn',{staticClass:"px-1 ma-0",attrs:{"text":"","color":"msaBlue"},on:{"click":function($event){return _vm.$emit('toggleIsDialogVisible', 'top')}}},[_vm._v(" EDIT ")]):_vm._e()],1):_vm._e()],1),_c('v-row',{class:_vm.iconTextRowSpacing},[_c('v-col',{staticClass:"shrink"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-account-outline")])],1),_c('v-col',{staticClass:"grow",class:{ 'black--text font-weight-medium': _vm.title != 'Title' },style:({ 'word-break': 'break-word' })},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('v-row',{class:_vm.iconTextRowSpacing},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-phone-outline")])],1),_c('v-col',{class:{
        'black--text font-weight-medium': _vm.phoneNumber != 'Phone Number',
      }},[_vm._v(" "+_vm._s(_vm.phoneNumber)+" ")])],1),_c('v-row',{staticClass:"d-flex nowrap",class:_vm.iconTextRowSpacing},[_c('v-col',{staticClass:"d-flex nowrap",attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-email-outline")])],1),_c('v-col',{class:{ 'black--text font-weight-medium': _vm.email != 'Email' }},[_vm._v(" "+_vm._s(_vm.email)+" ")])],1),(_vm.isLoewenUser)?_c('v-row',{class:_vm.iconTextRowSpacing},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-file-account-outline")])],1),_c('v-col',{class:{
        'black--text font-weight-medium':
          _vm.remoteEmployeeNumber != 'Employee Number',
      }},[_vm._v(" "+_vm._s(_vm.remoteEmployeeNumber)+" ")])],1):_vm._e(),(_vm.isLoewenUser)?_c('v-row',{class:_vm.iconTextRowSpacing},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-account-supervisor-outline")])],1),_c('v-col',{class:{
        'black--text font-weight-medium': _vm.supervisor != 'Supervisor',
      }},[_vm._v(" "+_vm._s(_vm.supervisor)+" ")])],1):_vm._e(),(_vm.isLoewenUser)?_c('v-row',{class:_vm.iconTextRowSpacing},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-home-outline")])],1),_c('v-col',{class:{
        'black--text font-weight-medium': _vm.department != 'Department',
      }},[_vm._v(" "+_vm._s(_vm.department)+" ")])],1):_vm._e(),(_vm.isLoewenUser)?_c('v-row',{class:_vm.iconTextRowSpacing},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-alpha-j-circle-outline")])],1),_c('v-col',{class:{
        'black--text font-weight-medium': _vm.jobCode != 'Job Code',
      }},[_vm._v(" "+_vm._s(_vm.jobCode)+" ")])],1):_vm._e(),(_vm.isLoewenUser)?_c('v-row',{class:_vm.iconTextRowSpacing},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-office-building-outline")])],1),_c('v-col',{class:{
        'black--text font-weight-medium':
          _vm.primaryWorkLocation != 'Primary Work Location',
      }},[_vm._v(" "+_vm._s(_vm.primaryWorkLocation)+" ")])],1):_vm._e(),_vm._l((_vm.userData.userCustomFields),function(inputField){return _c('v-row',{key:inputField.id,class:_vm.iconTextRowSpacing},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-pencil-box-outline")])],1),_c('v-col',{class:{
        'black--text font-weight-medium': inputField.value.length,
      }},[_vm._v(" "+_vm._s(!inputField.value.length ? inputField.name : inputField.name + ': ' + inputField.value)+" ")])],1)}),_c('v-row',{class:_vm.iconTextRowSpacing},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-calendar-check-outline")])],1),_c('v-col',{class:{ 'black--text font-weight-medium': _vm.hireDate != 'Hire Date' }},[_vm._v(" "+_vm._s(_vm.hireDate)+" ")])],1),_c('v-row',{class:_vm.iconTextRowSpacing},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-calendar-account-outline")])],1),_c('v-col',{staticClass:"black--text font-weight-medium"},[_vm._v(" Added: "+_vm._s(_vm.userData.createdAt)+" ")])],1),(!_vm.isUserProfile)?_c('v-row',{staticClass:"align-center mt-4"},[_c('v-col',{staticClass:"shrink"},[_c('label',[_vm._v("Status")])]),_c('v-col',{staticClass:"grow"},[_c('v-chip',{attrs:{"color":_vm.chipColour}},[_c('v-switch',{attrs:{"dense":"","true-value":1,"false-value":0,"color":_vm.switchColour,"readonly":_vm.isSwitchDisabled},on:{"change":function($event){return _vm.promptStatusChange()}},model:{value:(_vm.userData.status),callback:function ($$v) {_vm.$set(_vm.userData, "status", $$v)},expression:"userData.status"}}),_c('label',{class:_vm.labelTextColour},[_vm._v(" "+_vm._s(_vm.switchLabel)+" ")])],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }